@import './variables.scss';

.slick-slide>div>div {
    height: 100%;
}

.slick-slide>div {
    height: 100%;
}

.slick-track {
    display: flex;
}

.slick-track .slick-slide {
    display: flex;
    height: inherit;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.b2-btn-primary {
    transition: all 0.1s ease-in-out;
    border-radius: 0.3em;
}

.b2-btn-primary:hover {
    transform: scale(1.025);
}

.b2-btn-primary:active {
    transform: scale(1);
}

.b2-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}

.img-bg-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.b2-floating-whatsapp {
    background-color: #26D366;
    position: fixed;
    z-index: 25;
    right: min(3rem, 5vw);
    bottom: min(3rem, 5vh);
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4.5rem;
    box-shadow: 3px 3px 20px rgb(0, 0, 0, 0.2);
    color: white;
    font-size: 2.5rem;
    cursor: pointer;
}

.hover-scale {
    transition: all 0.1s ease-in-out;
}

.hover-scale:hover {
    transform: scale(1.025);
}

.hover-scale:active {
    transform: scale(1);
}

@media (min-width: 992px) {
    .b2-container {
        max-width: 90%;
    }
}

.text-shadow-embossed {
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
        0px -4px 10px rgba(0, 0, 0, 0.3);
}

.filter-shadow-embossed {
    filter: drop-shadow(2px 7px 5px rgba(0, 0, 0, 0.3));
    -webkit-filter: drop-shadow(2px 7px 5px rgba(0, 0, 0, 0.3));
}

.img-filter-bright-25 {
    filter: brightness(25%)
}

.img-filter-bright-50 {
    filter: brightness(50%)
}

.img-filter-bright-75 {
    filter: brightness(75%)
}

.img-filter-bright-25-blur {
    filter: brightness(25%) blur(5px);
}

.animate-fade-in {
    animation: fadeInUp ease-out 750ms;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

.animate-delay-500 {
    animation-delay: 500ms;
}
 
@keyframes fadeInUp {
    0% {
        transform: translate3d(0,40px,0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
     }
}


.promo-toast {
    position: fixed;
    z-index: 25;
    left: min(3rem, 5vw);
    bottom: min(3rem, 5vh);
    background-color: #fafafa;
    border: 1px;
    max-width: 70vw;
}