@import "../styles/variables.scss";

footer {
  background: $background-dark;
  color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 80vw;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}


.footer-logo {
  width: 2.5rem;
  height: 2.5rem;
  color: white;
}

.footer-social-links {
  display: flex;
  gap: 1rem;
}

.footer-social-links a {
  color: white;
  font-size: 1.5rem;
  transition: all 0.2s ease-in-out;
}

.footer-social-links a:hover {
  transform: scale(1.1);
  color: $primary;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-columns ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-columns li{
  margin-bottom: 0.5rem;
}

.footer-columns a{
  color: white;
}

.footer-columns a:hover{
  color: $primary;
}

.footer-divider {
  width: 100%;
  border: 1px solid #595C61;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer-bottom {
  text-align: center;
}