@import "../styles/variables.scss";

.navbar-container {
    background-color: rgb(0, 0, 0, 0);
    transition: background-color 300ms linear;
    color: white;
    position: sticky;
    top: 0px;
    box-sizing: border-box;
    padding: 0.5rem 2rem 0.5rem 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 900;
}

.navbar-scrolled {
    background-color: $background-dark;
    transition: background-color 300ms linear;
    color: white;
}

.navbar-logo {
    width: 5rem;
    height: 5rem;
    padding: 0.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    box-sizing: border-box;
    color: $primary;
    cursor: pointer;
}



.navbar-menu {
    display: flex;
    gap: 3rem;
    font-weight: 700;
}


.navbar-menu a {
    color: white;
}

.navbar-menu>a:hover {
    color: $primary;
}


.navbar-button {
    background-color: $primary;
    color: white;
    font-weight: 500;
    padding: 0.75em;
    transition: all 0.1s ease-in-out;
    border-radius: 0.3em;
}

.navbar-button:hover {
    transform: scale(1.05);
}

.navbar-button:active {
    transform: scale(1);
}

.navbar-mobile-menu-container {
    position: fixed;
    background-color: $background-dark;
    height: 100%;
    box-sizing: border-box;
    padding: 2rem;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2000;
}

.navbar-mobile-menu-links {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.navbar-mobile-menu-links a {
    color: white;
    font-weight: 700;
    font-size: 1.25rem;
}

.navbar-mobile-menu-links a:hover {
    color: $primary;
}

.navbar-mobile-menu-container-toggle {
    display: none;
    font-size: 2rem;
    color: $primary;
    cursor: pointer;
}

.fade-in-animate {
    animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}




@media (max-width: 768px) {
    .navbar-mobile-hide {
        display: none;
    }

    .navbar-mobile-show {
        display: inline;
    }
}